.video__box{
    position: absolute;
    text-align: center;
    width: 100%;
    height: 100%;
    &, video, .viewport-header {
      @include coverer;
  }
}
video{
      object-fit: fill;
}