
.catalogo{
    margin:2rem 1rem;
    grid-area: info;
}
.catalogo__info{
    align-items: center;
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    font-size: 1.2rem;
    padding: 1rem 1rem 1rem;
}
.info_icono{
    width: 3rem;
    margin-bottom: 1rem;
}
@media (min-width: 700px) {
.info{
    flex-direction: row;
    justify-content: center;
    width: 70%;
    & p{
        width:60vw
    }
}

}
@media (min-width: 1080px) {
    .catalogo{
        display: flex;
     
    }
    .info_icono{
        width: 4rem;
    }

    .catalogo__info{
        width: 35vw;
        margin: 5rem 0 4rem;
    }
}