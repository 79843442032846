
.contact{
    align-items: center;
    background-color: black;
    color:$gold;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.agencia{
    grid-area: age;
    height: 90%;
    margin: 2rem 0;
    padding: 0.5rem;
    width: 80%;

}
.contacto{
    background-color: $gold;
    color:black;
    grid-area: ofi;
    padding: 1rem;
    list-style: none;
    & h2{
        margin-bottom: 1rem;
    }
}
.contact__icon{
    margin-right: 0.2rem;
    width: 1.3rem;
}
    @media (min-width: 1080px) {

        .contact{
            align-items: start;
            display: grid;
            font-size: 1.3rem;
            grid-template-columns: repeat(2,auto);
            grid-template-areas: 
            "age  ofi";
            margin-top:2rem
        }
        .agencia{
            width: 30vw;
            margin: 2rem;
        }
        .contacto{
            background-color: black;
            color: $gold;
            margin: 2rem 0 2rem -1rem;
            width: 35vw;
        }
        
    }