@mixin coverer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  background-color: black;
  color: ivory;
  height: auto;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  text-align: center;
  width: 100vw;
}
main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  color: $gold;
  margin-top: 1rem;
}
a {
  text-decoration: none;
  color: $gold;
}
ul {
  list-style: none;
}
span {
  font-size: 0.4rem;
}
.continente__box {
  padding-top: 6rem;
}
.contacto__page {
  margin-top: 6rem;
}
.custom__button {
  background-color: rgba($color: $gold, $alpha: 0.9);
  border: 1px solid $gold;
  border-radius: 5px;
  color: black;
  font-size: 1.2rem;
  font-weight: bolder;
  padding: 0.5rem 1.5rem;
}

header {
  align-items: center;
  background-image: url("../../images/portada.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 85vh;
  justify-content: center;
  margin: 6rem 0 2rem;
}

@media (min-width: 750px) {
  header {
    height: 100vh;
    & h1 {
      visibility: hidden;
    }
  }
  .contacto__page {
    margin-top: 8rem;
  }
  .continente__box {
    padding-top: 14rem;
  }
  .socialmedia {
    visibility: hidden;
  }
}

@media (min-width: 1000px) {
  .contacto__page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 12rem;
  }
  .continente__box {
    padding-top: 15rem;
  }
}
