.carousel {
    width: 100%;
    margin: auto;
    overflow: hidden;
    align-items: center;
    height: 15rem;
    grid-area: car;
  }
  .carousel ul {
    align-items: center;
    display: flex;
    flex-grow: 1;
    padding: 0;
    width: 400%;
    animation: cambio 20s infinite alternate linear;
  }
  .photo {
      list-style: none;
      width: 100vw;
  }
  .carousel img {
    width: 100vw;
    height: 15rem;
  }
  @keyframes cambio {
    0%   {margin-left:     0;}
    15%  {margin-left:     0;}
    20%  {margin-left: -100%;}
    35%  {margin-left: -100%;}
    40%  {margin-left: -200%;}
    55%  {margin-left: -200%;}
    60%  {margin-left: -300%;}
    75%  {margin-left: -300%;}
    80%  {margin-left: -400%;}
    100% {margin-left: -400%;}
  }
  @media (min-width: 750px) {

    .carousel{
      height: auto;
    }
    .carousel ul{
      width: 100%;
    }
    .carousel img {
      width: 25rem;
      height: 15rem;
      margin: 1rem;
    }
    @keyframes cambio {
    0%   {margin-left:     0;}
    25%  {margin-left:  -25%;}
    50%  {margin-left:  -50%;}
    75%  {margin-left:  -75%;}
    100% {margin-left:  -120%;}
  }
  }
  @media (min-width: 1080px) {
 .carousel{
      height: auto;
    }
    .carousel ul{
      width: 100%;
      animation: cambio 15s infinite alternate linear;
    }
    .carousel img {
      width: 35rem;
      height: 25rem;
      margin: 1rem;
    }
    @keyframes cambio {
    0%   {margin-left:     0;}
    25%  {margin-left:  -25%;}
    50%  {margin-left:  -50%;}
    75%  {margin-left:  -70%;}
    100% {margin-left:  -90%;}
  }

  }
