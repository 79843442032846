.modal__container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  text-align: center;
  z-index: 10;
}
.modal__container .modal__box {
  background: rgba($color: #000000, $alpha: 0.95);
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  & ul {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 75vh;
  }
}

.modal__header {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  width: 100vw;
}
.modal__icon__close {
  height: 1.5rem;
}
.modal__box li {
  font-size: 2rem;
  color: $gold;
}
.modal__logo {
  height: 6rem;
}
