
.destinos{
    align-items: center;
    display: flex;
    flex-direction: column;
}
.destino__item{
    max-width: 100vw;
    margin: 0 1rem;
}
.destino__container{
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100vw;
}
.destino__box{
    margin: 0.5rem 1rem;
    position:relative;
    max-width: 100%;
}
 .destino__box img {
     position:relative;
     width:90vw;
     max-height:auto;
     } 
.destino__box p{
    color: ivory;
    font-size: 2rem;
    margin: 0.5rem;
    position:absolute ;
    top:0;
    left:0;
    text-shadow: 2px 2px 2px black;
     }
.catalogos{
    margin-top: 2rem;
}
.catalogo_container{
    align-items: center;
    display: flex;
    justify-content: center;
}
.catalogo__box{
    background-size: cover;
    height: 15rem;
    margin: 1rem;
}
.catalogo__box__link{
    color:ivory;
    font-size: 1.5rem;
    padding-top: 0.5rem;
    text-shadow:2px 2px 2px black;
}
.contacto__destino{
    align-items: center;
    background-image: url(../../images/contacto.jpeg);
    background-size: cover;
    color:ivory;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 15rem;
    margin: 3rem 0 1rem; 
    max-width: 100vw;
    text-shadow:1px 1px 1px black;
}


.todos__destinos__container{
    margin: 7rem 0 2rem;
}
.destino__searcher_input{
    background-color: rgba($color:ivory, $alpha: 0.8);
    font-size:1.3rem ;
    margin: 1rem;
    padding:0.6rem;
}



@media (min-width: 750px) {
   .destinos{
       align-items: center;
       display: flex;
       flex-direction: column;
       flex-wrap: wrap;
       justify-content: center;
       margin-top: 1rem;
   }
   .destino__item{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 100vw;
    margin: 5rem 1rem 4rem;
}
   .destino__box img{
       width: 40rem;
       height: 25rem;
   }
   .destino__box p{
       font-size:2.5rem
   }
   .catalogos{
       margin-top:5rem;
   }
   .contacto__destino{
       padding-bottom: 2rem;
       font-size: 1.5rem;
        height: 25rem;
        margin: 5rem 0;
        width: 40rem;
   }
   .catalogo__container{
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
   }
   .catalogo__box{
    background-size: cover;
    height: 15rem;
    width: 25rem;

}
}
@media (min-width: 1000px) {
.catalogos{
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10rem;
}

.contacto__destino{
        height: 30rem;
        margin: 5rem 0;
        width: 50rem;
}
}
@media (min-width: 1200px) {
    .destinos__container{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

}