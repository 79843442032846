.cookies {
  background-color: black;
  color: lighten($color: $gold, $amount: 30);
  padding: 2rem;
  position: fixed;
  top: 60%;
  width: 100vw;
  z-index: 12;
}
.cookies__button {
  border-radius: 4px;
  background-color: lighten($color: $gold, $amount: 20);
  font-weight: bolder;
  margin: 0.5rem 0 0 0.5rem;
  padding: 0.3rem 0.5rem;
}
.cookies__link {
  text-decoration: underline;
  margin: 0 0.5rem;
}

.politicaCookies {
  margin: 5rem 1rem 7rem;
  padding: 1rem;
}
.politicaCookies__p {
  margin: 1rem 0;
}

.aviso__legal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10rem;
  margin-bottom: 3rem;
  & h3 {
    margin: 1rem;
  }
  & h4 {
    margin: 1rem;
  }
}
.aviso_p {
  margin: 1rem;
}

@media (min-width: 700px) {
  .cookies {
    top: 80%;
  }
  .aviso__legal {
    margin-top: 15rem;
  }
  .politicaCookies {
    margin: 15rem 5rem 8rem;
    padding: 1rem;
  }
}
@media (min-width: 1200px) {
  .cookies {
    top: 75%;
  }
  .politicaCookies {
    margin: 15rem 5rem 8rem;
    padding: 1rem;
  }
}
