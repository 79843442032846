.custom__footer {
  align-items: center;
  background-color: $gold;
  bottom: 0;
  color: black;
  display: flex;
  flex-direction: column;
  height: 2rem;
  justify-content: center;
  position: fixed;
  width: 100vw;
  z-index: 10;
}
.footer__parrafo {
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}
.image__footer {
  max-width: 5rem;
}
.custom__link {
  display: flex;
  align-items: center;
  z-index: 2;
}
.politica__link {
  color: black;
  font-weight: bold;
  margin: 1rem;
}
@media (max-width: 600px) {
  .custom__footer {
    position: fixed;
    bottom: 1px;
    height: 4rem;
  }
  .footer__parrafo {
    position: relative;
    justify-content: space-evenly;
  }
  .politica__link {
    margin: 0.1rem;
  }
  .custom__link {
    justify-content: space-between;
    text-decoration: underline;
  }
}
