.nosotros{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    grid-area: nos;
    & h2{
        font-size: 2rem;
        margin-bottom: 1rem;
    }
    & img{
        width:10rem;
        margin:0  3rem 1rem;
    }
    & p{
        margin: 0 2rem;
    }
}

@media (min-width: 700px) {
.nosotros h2{
    font-size: 3rem;
    margin-bottom: 2rem;
}

.nosotros__section{
    display: flex;
    justify-content: center;
    align-items: center;
    & p{
        width: 50%;
    }
}

}
@media (min-width: 1000px) {

    .nosotros{
        margin: 8rem 0
    }
}
