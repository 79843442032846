
.socialmedia{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    & img{
        height: 3.5rem;
        margin: 1rem 0.5rem  ;
        width: 3.5rem;
}
}
.menubar_socialmedia{
    height: 2rem;
    margin: 0.2rem;
    width: 2rem;
}