.bloque__images{
    width: 100vw;
    height: 40vh;
    & p{
        background-color: rgba($color: $gold, $alpha: 0.3);
        font-size: 2rem;
        font-weight: bold;
        color: darken($color: $gold, $amount: 50);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: 0.5px 0.5px $gold;
    }
}
.container_novios {
    background-image: url(../../../images/novios.jpeg);
    background-size: cover;
}
.container_distancia{
    background-image: url(../../../images/lejos.jpeg);
    background-size: cover;
}
.container_familia{
    background-image: url(../../../images/disney.jpeg);
    background-size: cover;
}
.container_playa{
    background-image: url(../../../images/playa.jpeg);
    background-size: cover;
}
